import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import '../styles/card.css';

const useStyles = makeStyles({
	root: {
		maxWidth: 345,
	},
});

export default function ImgMediaCard({ image, alt, title, description }) {
	const classes = useStyles();

	return (
		<Card className="cards">
			{/* <CardActionArea> */}
				<CardMedia
					component="img"
					alt={alt}
					height="150"
					image={image}
					title={title}
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{title}
					</Typography>
					{/* <Typography variant="body2" color="textSecondary" component="p">
						{description}
					</Typography> */}
				</CardContent>
			{/* </CardActionArea> */}
			{/* <CardActions>
				<Button size="small" color="primary">
					Condividi
				</Button>
				<Button size="small" color="primary">
					Scopri di più
				</Button>
			</CardActions> */}
		</Card>
	);
}
