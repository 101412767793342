import React from 'react';
import Mappa from './contatti/mappa/Mappa';
import back from '../img/tech_back.jpg';
import Form from './contatti/form/Start_form';
import { PrivacyPolicy } from 'react-quick-legal';
class Contact extends React.Component {
	render() {
		return (
			<section
				className="paralax-mf footer-paralax bg-image sect-mt4 route"
				style={{
					backgroundImage:
						'url( https://www.ingegneriabernasconi.it/images/tech_back.jpg',
				}}
			>
				<div className="overlay-mf"></div>
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="contact-mf">
								<div id="contact" className="box-shadow-full">
									<div className="row">
										<div className="col-md-6">
											<div className="title-box-2">
												<h5 className="title-left">Invia una richiesta</h5>
											</div>
											<div>
												<Form />
											</div>
										</div>
										<div className="col-md-6">
											<div className="title-box-2 pt-4 pt-md-0">
												<h5 className="title-left">Dove</h5>
											</div>
											<Mappa />
											{/* Footer contact */}
											<div className="more-info">
												{/* <p className="lead">
													Looking for a passionate, dedicated and creative
													Frontend Web Developer? Contact me for more
													information on how I can help your team.
													<br />
													Simply fill the from and send me an email.
												</p> */}
												<ul className="list-ico">
													<li>
														<span className="ion-ios-location"></span>
														<a
															className="text_under_map"
															href="https://goo.gl/maps/73tgAzyVzJpsbh6b6"
															target="_blank"
															rel="noopener noreferrer"
														>
															Via del Sudario, 35 - 00186 Roma (RM)
														</a>
													</li>
													<li>
														<span className="ion-ios-telephone"></span>
														<a className="text_under_map" href="tel:0656558610">
															{' '}
															+39 0656558610
														</a>
													</li>
													<li>
														<span className="ion-email"></span>
														<a
															className="text_under_map"
															href="mailto:info@ingegneriabernasconi.it"
															target="_blank"
															rel="noopener noreferrer"
														>
															info@ingegneriabernasconi.it
														</a>
													</li>
												</ul>
											</div>
											<div className="socials">
												<ul>
													{/* <li>
														<a
															href="https://github.com/AD757"
															target="_blank"
															rel="noopener noreferrer"
														>
															<span className="ico-circle">
																<i className="ion-social-github"></i>
															</span>
														</a>
													</li> */}
													<li>
														<a
															href="https://www.linkedin.com/company/ing-eleonora-bernasconi-studio-di-ingegneria/"
															target="_blank"
															rel="noopener noreferrer"
														>
															<span className="ico-circle">
																<i className="ion-social-linkedin"></i>
															</span>
														</a>
													</li>

													<li>
														<a
															href="https://www.instagram.com/ingegneriabernasconi"
															target="_blank"
															rel="noopener noreferrer"
														>
															<span className="ico-circle">
																<i className="ion-social-instagram"></i>
															</span>
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<footer>
					<div className="container">
						<div className="row">
							<div className="col-sm-12">
								<div className="copyright-box">
									<p className="copyright">
										2020 &copy; <strong>Ing. Eleonora Bernasconi</strong>{' '}
									</p>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</section>
		);
	}
}

export default Contact;
