import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../../../styles/contatti.css'

export default function ContactUs() {

	const [from_name, setFrom_name] = useState(false);
	const [from_surname, setFrom_surname] = useState(false);
	const [from_mail, setFrom_mail] = useState(false);
	const [subject, setSubject] = useState(false);
	const [message, setMessage] = useState(false);

	function sendEmail(e) {
		e.preventDefault();

		if (from_name && from_surname && from_mail && message) {
			emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_USER_ID)
		
			.then((result) => {
				alert('Messaggio inviato!');
				window.location.reload();
			},
			
			(error) => {
				console.log(error.text);
			});
		}

		else {
			alert('Attenzione compilare i campi mancanti!');
		}
	}
    
	return (
		<form onSubmit={sendEmail} id="form">
			<div class="field">
				{/* <label for="from_name">Nome</label> */}
				<input type="text" placeholder="Nome" name="from_name" id="from_name" onChange={e => setFrom_name(e.target.value)}/>
			</div>
			<div class="field">
				{/* <label for="from_surname">Cognome</label> */}
				<input type="text" placeholder="Cognome" name="from_surname" id="from_surname" onChange={e => setFrom_surname(e.target.value)}/>
			</div>
			<div class="field">
				{/* <label for="from_mail">Email</label> */}
				<input type="email" placeholder="Email" name="from_mail" id="from_mail" onChange={e => setFrom_mail(e.target.value)}/>
			</div>
			<div class="field">
				{/* <label for="subject">Oggetto</label> */}
				<input type="text" placeholder="Oggetto" name="subject" id="subject" onChange={e => setSubject(e.target.value)}/>
			</div>
			<div class="field">
				{/* <label for="message">Messaggio</label> */}
				<textarea class="message-field" type="textarea" placeholder="Messaggio" name="message" id="message" onChange={e => setMessage(e.target.value)}/>
			</div>
			<button
				className="button button-a button-big button-rouded"
				type="submit"
				value="Invia"
				type="submit" 
				id="button"
			>Invia</button>
			
		</form>
	);
}

