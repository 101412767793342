import React from 'react';

import Card from './card';
import logo from '../img/logo.png';

import web_service from '../img/web_service_r.png';
import web_designer from '../img/web_designer_r.png';
import sist_inf from '../img/sist_inf_r.png';
import a_i from '../img/a_i_r.png';

import { SvgIcon } from '@material-ui/core';
import '../styles/about.css';

class About extends React.Component {
	constructor() {
		super();
		this.state = {
			about_me: [
				// {
				// 	id: 'first-p-about',
				// 	content:
				// 		"My name is Alladin Daher, born and raised in Uppsala, Sweden. I'm a Frontend Web Developer with experience in using HTML5, CSS3, Javascript, React & Node.js. If you navigate to the projects section of this website, you'll see some of the Frontend web work I've accomplished.",
				// },
				// {
				// 	id: 'second-p-about',
				// 	content:
				// 		'I come from a background of sports and coaching, but has found the world of web development to be my true passion. I enjoy to continue learning to improve my knowledge and to gain new experience. When not coding, you will find me working out, playing football or gaming',
				// },
				// {
				// 	id: 'third-p-about',
				// 	content:
				// 		"I want to take my passion, education, and experience to develop websites and applications. I'm looking for a full time job and I'm actively applying to related jobs. I am open to frontend web dev and fullstack web dev. In a company, I value culture, impact and mission. Please feel free to contact me for job opportunities!",
				// },
				{
					id: 'servizi_web',
					content: web_service,
					title: 'Servizi web',
					description: '',
				},
				{
					id: 'sist_inf',
					content: sist_inf,
					title: 'Sistemi informativi',
					description: '',
				},
				{
					id: 'a_i',
					content: a_i,
					title: 'Intelligenza Artificiale',
					description: '',
				},
				{
					id: 'web_designer',
					content: web_designer,
					title: 'Web designer',
					description: '',
				},
			],
		};
	}

	render() {
		return (
			<section id="about" className="about-mf sect-pt4 route">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="box-shadow-full">
								<div className="row">
									<div className="col-md-6">
										<div className="row">
											<div
												className="col-sm-6 col-md-5"
												style={{ margin: '0 auto' }}
											>
												<div
													className="about-img"
													style={{ textAlign: 'center' }}
												>
													<img src={logo} alt="logo" className="img_logo" />
												</div>
											</div>
										</div>

										<div className="col-md-6">
											<div className="about-me pt-4 pt-md-0">
												<div className="title-box-2">
													<h5 className="title-left">Skills</h5>
												</div>
												<div className="skills-text">
													<p>
														<iconify-icon data-icon="logos:python"></iconify-icon>{' '}
														Python <br /> <br />
														<iconify-icon data-icon="logos:html-5"></iconify-icon>{' '}
														HTML / HTML5 <br /> <br />
														<iconify-icon data-icon="logos:css-3"></iconify-icon>{' '}
														CSS / CSS3 <br /> <br />
														<iconify-icon data-icon="logos:javascript"></iconify-icon>{' '}
														Javascript <br /> <br />
														<iconify-icon data-icon="logos:react"></iconify-icon>{' '}
														React JS <br /> <br />
														<iconify-icon data-icon="logos:nodejs"></iconify-icon>{' '}
														Node JS <br /> <br />
														<iconify-icon data-icon="logos:php"></iconify-icon>{' '}
														Php <br /> <br />
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="about-me pt-4 pt-md-0">
											<div className="title-box-2">
												<h5 className="title-left">Servizi</h5>
											</div>
											{this.state.about_me.map((content) => {
												return (
													// <p className="lead" key={content.id}>
													// 	{content.content}
													// </p>
													<div className="servizi">
														<Card
															image={content.content}
															alt={content.id}
															title={content.title}
															description={content.description}
														/>
													</div>
													// <img
													// 	className="im_servizi"
													// 	alt={content.id}
													// 	src={content.content}
													// />
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default About;
