import React, { Component } from 'react';
import '../../../styles/mappa.css';

class SimpleMap extends Component {
	render() {
		return (
			// Important! Always set the container height explicitly
			<div>
				<iframe
					className="mappa"
					src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11879.4310083316!2d12.4760295!3d41.8959162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf7ffb6b6e3858bcf!2sIng.%20Eleonora%20Bernasconi%20Studio%20di%20Ingegneria%20dell&#39;Informazione%20-%20Information%20and%20Communication%20Technology!5e0!3m2!1sit!2sit!4v1592643040506!5m2!1sit!2sit"
				></iframe>
			</div>
		);
	}
}

export default SimpleMap;
