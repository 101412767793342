import React from 'react';
import Typed from 'react-typed';
import Divider from '@material-ui/core/Divider';
import './stars.scss';
import '../styles/intro.css';

class Intro extends React.Component {
	render() {
		return (
			// <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
			<div id="home" className="intro route bg-image background">
				<div id="stars" />
				<div id="stars2" />
				<div id="stars3" />

				<div className="intro-content display-table">
					<div className="table-cell">
						<div className="container">
							<h1 className="title">Ing. Eleonora Bernasconi</h1>
							{/* <p className="pt-3"> Studio di Ingegneria dell'Informazione </p> */}
							<div>
								<Divider />
							</div>
							<p className="subtitle_large">
								Studio di Ingegneria dell'Informazione - Information and
								Communication Technology
							</p>
							<p className="subtitle_small">
								<div>Studio di Ingegneria dell'Informazione</div>
								<p></p>
								<div>Information and Communication Technology</div>
							</p>

							{/* <p className="intro-subtitle">
								<span className="text-slider-items"></span>
								<strong className="text-slider">
									<Typed
										strings={['Information and', 'Communication Technology']}
										typeSpeed={80}
										backDelay={1100}
										backSpeed={30}
										loop
									/>
								</strong>
							</p> */}
							<p className="pt-3">
								<a
									className="btn btn-primary btn js-scroll px-4"
									href="#about"
									role="button"
								>
									Benvenuti
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Intro;
