import React from 'react';
import Form from './Form';

class Home_form extends React.Component {
	render() {
		return (
			<div class="app_form">
				<Form />
			</div>
		);
	}
}

export default Home_form;
