import React from 'react';
import RomeBP from '../img/RomeBP.png';
import Arca from '../img/Arca.png';
import BookReviews from '../img/BookReviews.png';
import FlackChat from '../img/FlackChat.png';
import ItalianPizza from '../img/ItalianPizza.png';
import updating from '../img/updating.jpg';

import harvard from '../img/harvardlogo.svg';
import '../styles/portfolio.css';

class Portfolio extends React.Component {
	render() {
		return (
			<section id="work" className="portfolio-mf sect-pt4 route">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="title-box text-center">
								<h3 className="title-a">Portfolio</h3>
								<p className="subtitle-a">
									I miei progetti <br />
								</p>
								<div className="line-mf"></div>
							</div>
						</div>
					</div>

					{/* Project 1 */}
					<div className="row">
						{/* <div className="col-md-4"> */}
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src={RomeBP}
										alt="Dice-Game"
										className="img-fluid"
										style={{ color: 'black' }}
									></img>
								</div>
								<div className="label_w">
									<a
										className="a_label_w"
										target="_blank"
										href="https://certificates.cs50.io/96ca7364-5548-4f30-a0e9-65ec7254f583.pdf?size=letter"
									>
										Sviluppato per Harvard University
									</a>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">RomeBP</h2>
											<p>
												The Best Places in Rome. Scopri
												i migliori posti di Roma.
												Esplora la mappa!
											</p>
											{/* <a
												target="_blank"
												href="https://cs50.harvard.edu/web/2020/"
											>
												CS50WX - Harvard
											</a> */}

											<iconify-icon data-icon="dashicons:html"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="bx:bxs-file-css"></iconify-icon>
											<iconify-icon data-icon="cib:sass-alt"></iconify-icon>
										</div>
										{/* <div className="col-sm-4">
											<div className="socials">
												<a
													href="https://www.ingegneriabernasconi.it/ProgettoBPRome/index.html"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="view_site"></i>
													</span>
												</a>
											</div>
										</div> */}
									</div>
								</div>

								{/* Project 2 */}
							</div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src={Arca}
										alt="Calculator"
										className="img-fluid"
										style={{ color: 'black' }}
									></img>
								</div>
								<div className="label_w">
									<a
										className="a_label_w"
										target="_blank"
										href="https://www.iltempo.it/lavoro-adn-kronos/2020/04/09/news/libri-arca-online-i-patrimoni-editoriali-italiani-1312324/"
									>
										Sviluppato per la Sapienza Università di
										Roma
									</a>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">Arca</h2>
											<p>
												Academic Research Creativity
												Archive. Esplora i contenuti dei
												libri di una casa editrice!
											</p>
											<iconify-icon data-icon="cib:react"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="dashicons:html"></iconify-icon>
											<iconify-icon data-icon="bx:bxs-file-css"></iconify-icon>
										</div>
										{/* <div className="col-sm-4">
											<div className="socials">
												<a
													href="http://arca.diag.uniroma1.it:5000/login"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="view_site"></i>
													</span>
												</a>
											</div>
										</div> */}
									</div>
								</div>

								{/* Project 3 */}
							</div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src={BookReviews}
										alt="Webpage pic"
										className="img-fluid"
										style={{ color: 'black' }}
									></img>
								</div>
								<div className="label_w">
									<a
										className="a_label_w"
										target="_blank"
										href="https://certificates.cs50.io/96ca7364-5548-4f30-a0e9-65ec7254f583.pdf?size=letter"
									>
										Sviluppato per Harvard University
									</a>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">
												Book Reviews
											</h2>
											<p>
												Esplora le recensioni di un
												catalogo di 5 mila libri e
												recensisci i tuoi libri
												preferiti.
											</p>

											<iconify-icon data-icon="cib:sass-alt">
												{' '}
											</iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="logos:flask"></iconify-icon>
											<iconify-icon data-icon="cib:postgresql"></iconify-icon>
										</div>
										{/* <div className="col-sm-4">
											<div className="socials">
												<a
													href="https://cs50wproject.herokuapp.com/"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="view_site"></i>
													</span>
												</a>
											</div>
										</div> */}
									</div>
								</div>

								{/* Project 4 */}
							</div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src={FlackChat}
										alt="Webpage pic"
										className="img-fluid"
										style={{ color: 'black' }}
									></img>
								</div>
								<div className="label_w">
									<a
										className="a_label_w"
										target="_blank"
										href="https://certificates.cs50.io/96ca7364-5548-4f30-a0e9-65ec7254f583.pdf?size=letter"
									>
										Sviluppato per Harvard University
									</a>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">
												Flack Chat
											</h2>
											<p>
												Flack Chat. Una chat aziendale
												privata composta da gruppi e
												stanze.
											</p>
											<iconify-icon data-icon="simple-icons:socket-dot-io"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="logos:flask"></iconify-icon>
											<iconify-icon data-icon="cib:postgresql"></iconify-icon>
										</div>
										{/* <div className="col-sm-4">
											<div className="socials">
												<a
													href="https://flackchat2020.herokuapp.com/"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="view_site"></i>
													</span>
												</a>
											</div>
										</div> */}
									</div>
								</div>

								{/* Project 5 */}
							</div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src={ItalianPizza}
										alt="Webpage pic"
										className="img-fluid"
										style={{ color: 'black' }}
									></img>
								</div>
								<div className="label_w">
									<a
										className="a_label_w"
										target="_blank"
										href="https://certificates.cs50.io/96ca7364-5548-4f30-a0e9-65ec7254f583.pdf?size=letter"
									>
										Sviluppato per Harvard University
									</a>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">
												Italian Pizza
											</h2>
											<p>
												Un e-commerce per gestire gli
												ordini e vendere prodotti
												online.
											</p>
											<iconify-icon data-icon="cib:python"></iconify-icon>
											<iconify-icon data-icon="cib:django"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="cib:heroku"></iconify-icon>
										</div>
										{/* <div className="col-sm-4">
											<div className="socials">
												<a
													href="https://italianpizzacs50w.herokuapp.com/"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="view_site"></i>
													</span>
												</a>
											</div>
										</div> */}
									</div>
								</div>

								{/* Project  6*/}
								{/* </div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src="https://i.imgur.com/OSVvLx9.png"
										alt="Weather-Webapp"
										className="img-fluid"
										style={{ color: 'black', height: '250px' }}
									></img>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">WEATHER APP</h2>
											Under progress, Weather web app that provides current
											weather from an API call. Built with React.
											<br />
											<iconify-icon data-icon="dashicons:html"></iconify-icon>
											<iconify-icon data-icon="bx:bxs-file-css"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="ion:logo-react"></iconify-icon>
											<iconify-icon data-icon="fa-brands:node"></iconify-icon>
										</div>

										<div className="col-sm-4">
											<div className="socials">
												<a
													href="https://github.com/AD757/"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="ion-social-github"></i>
													</span>
												</a>
											</div>
										</div>
									</div>
								</div> */}

								{/* Project  7*/}
								{/* </div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src="https://i.imgur.com/RiK1Hbl.png"
										alt="Note-Webapp"
										className="img-fluid"
										style={{ color: 'black', height: '250px' }}
									></img>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">QUIRE</h2>
											School team project. Quire is an online note taking
											application, which enables you to create, view, and manage
											notes.
											<br />
											<iconify-icon data-icon="dashicons:html"></iconify-icon>
											<iconify-icon data-icon="bx:bxs-file-css"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
										</div>

										<div className="col-sm-4">
											<div className="socials">
												<a
													href="https://github.com/AD757/fe19tp1_TeamStaple"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="ion-social-github"></i>
													</span>
												</a>
											</div>
										</div>
									</div>
								</div> */}

								{/* Project  8*/}
								{/* </div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src="https://i.imgur.com/HeY0szC.png"
										alt="Budget-Webapp"
										className="img-fluid"
										style={{ color: 'black', height: '250px' }}
									></img>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">BUDGET APP</h2>
											A web budgeting app that calculates basic budget based on
											a list of income and expenses.
											<br />
											<iconify-icon data-icon="dashicons:html"></iconify-icon>
											<iconify-icon data-icon="bx:bxs-file-css"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
										</div>

										<div className="col-sm-4">
											<div className="socials">
												<a
													href="https://github.com/AD757/Budget-App"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="ion-social-github"></i>
													</span>
												</a>
											</div>
										</div>
									</div>
								</div> */}

								{/* Project  9*/}
								{/* </div>
						</div>
						<div className="col-md-4">
							<div className="work-box">
								<div className="work-img">
									<img
										src="https://i.imgur.com/Rk6TK2E.png"
										alt="Recipe-Webapp"
										className="img-fluid"
										style={{ color: 'black', height: '250px' }}
									></img>
								</div>
								<div className="work-content">
									<div className="row">
										<div className="col-sm-8">
											<h2 className="w-title">FORKIFY</h2>
											Recipe Searching Application using JavaScript & NodeJS.
											Powered by Food2Fork API.
											<br />
											<iconify-icon data-icon="dashicons:html"></iconify-icon>
											<iconify-icon data-icon="bx:bxs-file-css"></iconify-icon>
											<iconify-icon data-icon="mdi:language-javascript"></iconify-icon>
											<iconify-icon data-icon="fa-brands:node"></iconify-icon>
										</div>

										<div className="col-sm-4">
											<div className="socials">
												<a
													href="https://github.com/AD757/Forkify"
													target="_blank"
													rel="noopener noreferrer"
												>
													<span className="ico-circle">
														<i className="ion-social-github"></i>
													</span>
												</a>
											</div>
										</div>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Portfolio;
